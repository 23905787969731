.product-d-flex {
  display: flex;
  /* flex-wrap: wrap; */
  /* padding: 0 8px; */
  justify-content: space-between;
  gap: 50px 10px;
}

.Detail-Image {
  max-width: 493.59px;
  min-width: 250px;
  width: 100%;
  /* height: 621.13px; */
  
}

.Detail-Image .bigimg {
  max-width: 493px;
  min-width: 250px;
  width: 100%;
  /* height: 493px; */
}
.detail4img {
  max-width: 492.59px;
  min-width: 250px;
  width: 100%;
  /* height: 114.13px; */
  
  display: flex;
  /* justify-content: space-between; */
  gap: 0 10px; /*rechek hoga*/
  padding-top: 14px;
}
.detail4img .smallimg {
  
  max-width: 114.13px;
  min-width: 20px;
  width: 100%;
  cursor: pointer;
  
  
}
.smallimg:hover{
  border: 2px solid black;
}

/* .smallimg.selected {
  border: 2px solid black;
} */



.Detail-content {
  max-width: 779px;
  min-width: 250px;
  width: 100%;
  /* height: 621px; */
 
}
.product-detail {
  max-width: 698px;
  min-width: 250px;
  width: 100%;
  /* height: 466px; */
}
.hader {
  max-width: 698px;
  min-width: 250px;
  width: 100%;
  text-align: justify;
}
.hader h2 {
  font-family: Roboto-Medium;
  font-weight: 500;
  font-size: 24px;
  line-height: 35px;
  
}
.hader .starclr {
  color: #ea854f;
}
.hader .star {
  padding: 9px 0;
}
.hader .text {
  font-family: Roboto-Regular;
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  text-align: justify;
  z-index: -1;
}
.keyfeature {
  margin: 26px 0;
}
.keyfeature h2 {
  font-family: Roboto-Medium;
  text-align: justify;
  font-weight: 500;
  font-size: 24px;
  line-height: 35px;
}
.tikicone li::before {
  content: "\1F5F8";
  color: white;
  font-size: 14px;
  background: black;
  border-radius: 50%;
  padding: 2px 6px;
  margin-right: 12px;
}
.tikicone li {
  font-family: Roboto-Regular;
  font-weight: 400;
  list-style: none;
  font-size: 18px;
  line-height: 30px;
  text-align: left;
  margin: 14px 0;
}

.btndiv {
  text-align: left;
  
}
.inqrybtn {
  max-width: 195px;
  min-width: 100px;
  width: 100%;
  /* height: 60px; */
  border-radius: 40px;
  background-color: black;
  color: white;
  font-size: 18px;
  line-height: 35px;
  font-family: Roboto-Bold;
  font-weight: 700;
  text-align: center;
}


.threeicon {
  max-width: 779px;
  min-width: 100px;
  width: 100%;
  /* height: 114px; */
  margin-top: 41px;
  box-shadow: 0px 0px 21.5px 0px #27aaf340;
}
.iconediv {
  /* height: 96px; */
  padding: 9px 13px;

  display: flex;
  /* flex-wrap: wrap; */
  justify-content: space-between;
  background-color: white;
}
.iconeimages {
  text-align: -webkit-center;
}

.iconeimages img {
  max-width: 51px;
  width: 100%;
  /* height: 51px; */
}
.threeicon p {
  font-family: Roboto-Medium;
  font-size: 20px;
  font-weight: 500;
  line-height: 35px;
  align-items: center;
}

.tablecontent {
  max-width: 1320px;
  min-width: 279px;
  width: 100%;
  /* height: 661px; */
}
.tbleHading {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  font-size: 22px;
  line-height: 25.78px;
  font-weight: 500;
  font-family: Roboto-Medium;
}
.table {
  max-width: 1318px;
  min-width: 279px;
  width: 100%;
  /* height: 500px; */
}


table {
  border-collapse: collapse;
  width: 100%;
}
th,
td {
  border: 1px solid black;
  padding: 14px 30px;
  text-align: left;
}
th {
  background-color: #f2faff;
}
.css-a5mhaz {
  
  outline: 2px solid transparent;
  outline-offset: 2px;
  padding: 20px 8px;
}
.css-1xhq01z {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  border-bottom: 2px solid;
  border-color: inherit;
  max-width: 1280px;
  min-width: 279px;
  width: 100%;
  padding: 0 0px;
}
.css-52dxnr {
  outline: 2px solid transparent;
  outline-offset: 2px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  transition-property: var(--chakra-transition-property-common);
  transition-duration: var(--chakra-transition-duration-normal);
  font-size: var(--chakra-fontSizes-md);
  padding-top: var(--chakra-space-2);
  padding-bottom: var(--chakra-space-2);
  -webkit-padding-start: var(--chakra-space-4);
  padding-inline-start: var(--chakra-space-4);
  -webkit-padding-end: var(--chakra-space-4);
  padding-inline-end: var(--chakra-space-4);
  border-bottom: 2px solid;
  border-color: var(--chakra-colors-transparent);
  margin-bottom: -2px;
  color: var(--tabs-color);
  background: var(--tabs-bg);
  width: 1320px;
  max-width: 1320px;
  min-width: 279px;
  width: 100%;
  margin: 0 auto;
}

.relatedproducts h1 {
  font-family: Roboto-Bold;
  font-size: 50px;
  font-weight: 700;
  line-height: 58.59px;
}
.reltd-card {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.card p {
  font-size: 16.27px;
  line-height: 31.63px;
  font-family: sans-serif;
  font-family: Roboto-Medium;
  font-weight: 500;
  padding: 20px;
  max-width: 320.25px;
  min-width: 200px;
  width: 100%;
  height: 100px;
}
.xt5y {
  /* padding: 0 0 0 16px ;
  position: -webkit-sticky;
  position: sticky;
  top: 134px;
  bottom: 0;
  z-index: -999;
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start; */
  position: sticky;
    top: 134px;
    align-self: flex-start;
}

.faq-container {
  /* Container styling */
  max-width: 779px;
  min-width: 200px;
  margin: 0 auto;
  padding: 0 2px;
}

.faq-section {
  /* Section styling */
  /* border-bottom: 1px solid #ccc; */
  margin: 25px 0;
  background: white;
    padding: 17px 40px;
    box-shadow: 0px 0px 4.5px 0px #27AAF340;

}

.faq-question {
  /* Question styling */
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: Roboto-Medium;
font-size: 22px;
font-weight: 500;
line-height: 25.78px;
text-align: left;

}

.arrow-icon {
  /* Arrow icon styling */
  margin-left: 10px;
  transition: transform 0.2s;
}

.arrow-icon.open {
  /* Arrow icon rotation when open */
  transform: rotate(180deg);
}

.faq-answer {
  /* Answer styling */
  /* padding-left: 20px; */
  padding-top: 5px;
  text-align: left;
  
  
}
.faq-answer-space{
  margin: 10px 0;
}
.faq-answer-space li{
  font-family: Roboto-Medium;
font-size: 18px;
font-weight: 500;
line-height: 21.09px;
text-align: left;

}
.faq-answer-space p{
  padding: 0 26px;
  font-family: Roboto-Regular;
font-size: 16px;
font-weight: 400;
line-height: 18.75px;
text-align: left;

}

@media screen and (max-width: 1024px) {
  .xt5y {
    /* padding: 0 0 0 16px ; */
    position: relative;;
    position: relative;
    top: 0;
    bottom: 0;
    z-index: -999;
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
  }
  .product-detail {
    max-width: 779px;
    min-width: 250px;
    width: 100%;
    /* height: 466px; */
  }
  .hader {
    max-width: 779px;
    min-width: 250px;
    width: 100%;
    text-align: justify;
  }
  .product-d-flex {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    justify-items: center;
    gap:  10px;
}
.hader h2 {
  font-family: Roboto-Medium;
  font-weight: 500;
  font-size: 20px;
  line-height: 35px;
}
.hader .text {
  font-family: Roboto-Regular;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  text-align: justify;
}
.keyfeature h2 {
  font-family: Roboto-Medium;
  text-align: justify;
  font-weight: 500;
  font-size: 20px;
  line-height: 35px;
}
.tikicone li {
  font-family: Roboto-Regular;
  font-weight: 400;
  list-style: none;
  font-size: 16px;
  line-height: 25px;
  text-align: justify;
  margin: 14px 0;
}
.inqrybtn {
  max-width: 195px;
  min-width: 100px;
  width: 100%;
  /* height: 60px; */
  border-radius: 40px;
  background-color: black;
  color: white;
  font-size: 16px;
  line-height: 35px;
  font-family: Roboto-Bold;
  font-weight: 700;
  text-align: center;
}
.threeicon p {
  font-family: Roboto-Medium;
  font-size: 20px;
  font-weight: 500;
  line-height: 23px;
  align-items: center;
}
  
  .faq-answer-space li{
    font-family: Roboto-Medium;
  font-size: 18px;
  font-weight: 500;
  line-height: 21.09px;
  text-align: left;
  
  }
  .faq-answer-space p{
    padding: 0 26px;
    font-family: Roboto-Regular;
  font-size: 16px;
  font-weight: 400;
  line-height: 18.75px;
  text-align: left;
  
  }
}
@media screen and (max-width: 767px) {
  .iconediv {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    justify-items: center;
    gap: 50px  10px;
  }
  .hader h2 {
    font-family: Roboto-Medium;
    font-weight: 500;
    font-size: 16px;
    line-height: 35px;
  }
  .hader .text {
    font-family: Roboto-Regular;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: justify;
  }
  .keyfeature h2 {
    font-family: Roboto-Medium;
    text-align: justify;
    font-weight: 500;
    font-size: 20px;
    line-height: 35px;
  }
  .tikicone li {
    font-family: Roboto-Regular;
    font-weight: 400;
    list-style: none;
    font-size: 14px;
    line-height: 20px;
    text-align: left;
    margin: 14px 0;
  }

  .inqrybtn {
    max-width: 195px;
    min-width: 100px;
    width: 100%;
    /* height: 60px; */
    border-radius: 40px;
    background-color: black;
    color: white;
    font-size: 16px;
    line-height: 35px;
    font-family: Roboto-Bold;
    font-weight: 700;
    text-align: center;
  }
  .threeicon p {
    font-family: Roboto-Medium;
    font-size: 16px;
    font-weight: 500;
    line-height: 23px;
    align-items: center;
  }
  .faq-section {
    /* border-bottom: 1px solid #ccc; */
    margin: 25px 0;
    background: white;
    padding: 17px 10px;
    box-shadow: 0px 0px 4.5px 0px #27AAF340;
}
  .faq-question {
    /* Question styling */
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: Roboto-Medium;
  font-size: 16px;
  font-weight: 500;
  line-height: 18.75px;
  text-align: left;
  
  }
  .faq-answer-space li{
    font-family: Roboto-Medium;
  font-size: 14px;
  font-weight: 500;
  line-height: 18.75px;
  text-align: left;
  
  }
  .faq-answer-space p{
    padding: 0 0 0 21px;
    font-family: Roboto-Regular;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.41px;
  text-align: left;
  
  }
  
  }