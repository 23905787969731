.product-flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 8px;
}
.product-page-padding{
  padding: 0 8px;
}
/* .product-selecter {
  max-width: 300px;
  min-width: 279px;
  width: 100%;
  border: 1px solid black;
  height: 693px;
  border-radius: 10px;
} */


.product-selecter {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  gap: 12px 19px;
  /* border-radius: 10px; */
  justify-content: center;
  padding: 16px 0;
}
.product-sticky{
  margin: 0;
    padding: 0;
    overflow: hidden;
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 127px;
    background: white;
    
}
.rectengle-one {
  max-width: 300px;
  min-width: 279px;
  width: 100%;
  height: 77px;
  border-radius: 10px 10px 0px 0px;
  border: 1px solid black;
  background-color: black;
  padding: 19px 21px;
}
.rectengle-one p {
  font-family: PTSans-Regular;
  gap: 0px;
  opacity: 0px;
  color: white;
  font-size: 24px;
  font-weight: 400;
  line-height: 35px;
  text-align: left;
  cursor: pointer;
}
.rectengle-all {
  /* max-width: 250px; */
  /* min-width: 222px; */
  /* width: 100%; */
  /* height: 77px; */
  /* border: 1px solid black; */
  background: #f2faff;
  padding: 8px 36px;
  cursor: pointer;
  /* border-radius: 10px 10px 0 0; */
  border-radius: 10px;
}

.rectengle-all p {
  font-family: PTSans-Regular;
  gap: 0px;
  
  color: black;
  font-size: 20px;
  font-weight: 400;
  line-height: 35px;
  text-align: center;
}
.rectengle-last {
  border: 1px solid black;
  background: #f2faff;
  padding: 19px 21px;
  border-radius: 0px 0px 10px 10px;
}
.rectengle-last p {
  font-family: PTSans-Regular;
  gap: 0px;
  opacity: 0px;
  color: black;
  font-size: 24px;
  font-weight: 400;
  line-height: 35px;
  text-align: left;
  cursor: pointer;
}
/* product card css start */
.product-card {
  max-width: 986px;
  min-width: 279px;
  width: 100%;
}
.card-flex {
  /* display: flex;
  flex-wrap: wrap;
  justify-content: space-between; */
  display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-items: center;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    gap: 50px 10px;
}
.card {
  /* max-width: 421.25px;
  min-width: 250px;
  width: 100%; */
  /* height: 398.56px; */
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  /* margin-bottom: 20px; */
  border-radius: 18.08px;
}
.card  img {
  max-width: 320.25px;
  min-width: 150px;
  width: 100%;
  height: 314.51px;
  border-radius: 18.08px 18.08px 0px 0px;
}
.card  .link-style-none{
  text-decoration: none;
  color: black;
}
.card .card-link p {
  font-size: 16.27px;
  line-height: 31.63px;
  font-family: sans-serif;
  font-family: Roboto-Medium;
  font-weight: 500;
  padding: 20px;
}

/* Pagination css start */
/* section {
  text-align: center;
} */

.pagination {
  display: inline-block;
  list-style-type: none;
  padding: 0;
}

.page-number {
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 18px;
  text-align: center;
  margin: 0 5px;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
}

.current {
  background-color: #ccc;
}
.page-arrow {
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 18px;
  text-align: center;
  margin: 0 5px;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
}
.disabled {
  color: #ccc;
  cursor: not-allowed;
}

/* Click Functionality */
.rectengle-all.active {
  background-color: black;
  color: white;
  
}
.rectengle-all.active p {
  color: white;
}
@media screen and (max-width:1024px) {
  .product-page-padding{
    padding: 0 30px;
  }
  .product-selecter {
           display: -webkit-box;
    flex-wrap: unset;
    margin: 0 auto;
    gap: 12px 19px;
    justify-content: center;
    padding: 16px 0;
  overflow-x: auto;
  -ms-scroll-snap-type: x mandatory;
      scroll-snap-type: x mandatory;
  scrollbar-width: none;
  }
  .rectengle-all {
    width: 250px;
    scroll-snap-align: start;
  }
  .card-flex {
    /* display: flex;
    flex-wrap: wrap;
    justify-content: space-between; */
    display: grid;
      grid-template-columns: repeat(3, 1fr);
      justify-items: center;
      align-items: center;
      align-content: center;
      justify-content: space-between;
      gap: 50px 10px;
  }
  .rectengle-all p {
    font-family: PTSans-Regular;    
    color: black;
    font-size: 16px;
    font-weight: 400;
    line-height: 35px;
    text-align: center;
}
.card p {
  font-size: 12px;
  line-height: 22.69px;
  font-family: sans-serif;
  font-family: Roboto-Medium;
  font-weight: 500;
  padding: 20px;
}
}
@media screen and (max-width:767px) {
  .product-page-padding{
    padding: 0 15px;
  }
  .card-flex {
    /* display: flex;
    flex-wrap: wrap;
    justify-content: space-between; */
    display: grid;
      grid-template-columns: repeat(1, 1fr);
      justify-items: center;
      align-items: center;
      align-content: center;
      justify-content: space-between;
      gap: 20px 10px;
  }
  .rectengle-all {
    width: 200px;
    scroll-snap-align: start;
  }
  .rectengle-all p {
    font-family: PTSans-Regular;    
    color: black;
    font-size: 13px;
    font-weight: 400;
    line-height: 19.68px;
    text-align: center;
}
.card p {
  font-size: 12px;
  line-height: 25.14px;
  font-family: sans-serif;
  font-family: Roboto-Medium;
  font-weight: 500;
  padding: 20px;
}
}

@media screen and (max-width:600px) {
  .product-sticky{
    margin: 0;
      padding: 0;
      overflow: hidden;
      position: -webkit-sticky; /* Safari */
      position: sticky;
      top: 86px;
      background: white;
  }
}
@media screen and (max-width:400px) {
  .page-number {
    display: inline-block;
    width: 25px;
    height: 25px;
    line-height: 24px;
    font-size: 15px;
    text-align: center;
    margin: 0 5px;
    border: 1px solid #ccc;
    border-radius: 50%;
    cursor: pointer;
  }
  
  .current {
    background-color: #ccc;
  }
  .page-arrow {
    display: inline-block;
    width: 25px;
    height: 25px;
    line-height: 24px;
    font-size: 15px;
    text-align: center;
    margin: 0 5px;
    border: 1px solid #ccc;
    border-radius: 50%;
    cursor: pointer;
  }
}
