.return-policy-bg{
    background-image: url(../images/returnpolicy.svg);
    background-position: center;
    background-repeat: no-repeat;
    /* background-size: contain; */
    max-width: 1320px;
    min-width: 250px;
    height: 450px;
    border-radius: 20px;
    display: flex; /* Flexbox ka use karke content ko center aur middle mein align karenge */
    justify-content: center; /* Horizontal alignment */
    align-items: center; /* Vertical alignment */
}
@media screen and (max-width: 1024px) {
    
    .return-policy-bg{
        background-image: url(../images/returnpolicy.svg);
        background-position: center;
        background-repeat: no-repeat;
        /* background-size: contain; */
        max-width: 964px;
        min-width: 250px;
        height: 450px;
        border-radius: 20px;
        display: flex; /* Flexbox ka use karke content ko center aur middle mein align karenge */
        justify-content: center; /* Horizontal alignment */
        align-items: center; /* Vertical alignment */
    }
}
@media screen and (max-width: 767px) {
    
    .return-policy-bg{
        background-image: url(../images/returnpolicy.svg);
        background-position: center;
        background-position: center;
        background-repeat: no-repeat;
        /* background-size: contain; */
        max-width: 738px;
        min-width: 200px;
        height: 250px;
        border-radius: 20px;
        display: flex; /* Flexbox ka use karke content ko center aur middle mein align karenge */
        justify-content: center; /* Horizontal alignment */
        align-items: center; /* Vertical alignment */
    }
}