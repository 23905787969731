
.whatsapp-icon {
    position: fixed;
    right: 20px;
    bottom: 20px;
    z-index: 1000;
    padding: 10px;
}

@media screen and (max-width: 768px) {
    

.whatsapp-icon {
    position: fixed;
    right: 2px;
    bottom: 0px;
    z-index: 1000;
    padding: 10px;
}
}