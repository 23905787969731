.footer-bg {
  background: rgba(0, 0, 0, 0.8);
}
.footer-space {
  padding: 119px 0;
}
.under-hover a {
  color: white;
}
.under-hover a:hover {
  color: #0096ea;
  transition: transform 4.3s;
}
.under-hover:hover {
  color: #0096ea;
  transition: transform 4.3s;
}
.d-flex-footer {
  display: flex;
  /* flex-wrap: wrap; */
  justify-content: space-between;
  align-items: center;
  gap: 25px;
  padding: 0 8px;
}
.part-1-width {
  width: 100%;
  max-width: 397px;
  min-width: 279px;
  height: 307px;
  /* border: 1px solid white; */
  text-align: left;
}

.flonix-white-logo img {
  width: 241px;
  height: 82px;
}
.footer-pera {
  margin: 15px 0 36px 0;
}
.footer-pera p {
  font-family: Roboto-Medium;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  text-align: justify;
  color: #ffffff;
}
.footer-icon-width {
  width: 223px;
  height: 44px;
  gap: 16px;
  /* border: 1px solid white; */
}
.footer-icon-flex {
  display: flex;
  justify-content: space-between;
}
.footer-icon-flex img {
  width: 44px;
  height: 44px;
}

.part-2-width {
  width: 100%;
  max-width: 175.88px;
  height: 297px;
  /* border: 1px solid white; */
  text-align: left;
}
.quick-link-heading {
  font-family: Roboto-Bold;
  font-weight: 700;
  font-size: 24px;
  line-height: 35px;
  color: white;
}
.quick-padding {
  padding: 15px 0 35px 0;
}

.links-policy p {
  font-family: Roboto-Medium;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  color: white;
}

.part-3-width {
  width: 100%;
  max-width: 175.88px;
  height: 307px;
  /* border: 1px solid white; */
  text-align: left;
}
.our-product {
  /* padding-top: 30px; */
}
.our-product p {
  font-family: Roboto-Medium;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  color: white;
}
.our-product p a {
  font-family: Roboto-Medium;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  color: white;
  text-decoration: none;
}
.part-4-width {
  width: 100%;
  max-width: 400.71px;
  min-width: 279px;
  height: 307px;
  /* border: 1px solid white; */
  text-align: left;
}
.copyright {
  background: black;
  color: white;
  padding: 15px 0;
}
.copyright .copy {
  font-family: Roboto-Regular;
  font-weight: 400;
  font-size: 18px;
  line-height: 35px;
}
.copyright .devlopBy {
  font-family: Roboto-Regular;
  font-weight: 400;
  font-size: 16px;
  line-height: 35px;
}
.copyright .devlopBy a{
  text-decoration: none;
  color: white;
}
@media screen and (max-width: 1024px) {
  .footer-space {
    padding: 50px 30px 80px 30px;
  }
  .d-flex-footer {
    display: flex;
    /* flex-wrap: wrap; */
    justify-content: space-between;
    align-items: center;
    gap: 25px;
    padding: 0 8px;
  }
  .part-1-width {
    width: 100%;
    max-width: 397px;
    min-width: 181px;
    height: 307px;
    /* border: 1px solid white; */
    text-align: left;
  }

  .flonix-white-logo img {
    width: 180px;
    height: 62px;
  }
  .footer-pera {
    margin: 15px 0 36px 0;
  }
  .footer-pera p {
    font-family: Roboto-Medium;
    font-weight: 500;
    font-size: 13px;
    line-height: 30px;
    text-align: justify;
    color: #ffffff;
  }
  .footer-icon-width {
    max-width: 397px;
    min-width: 181px;
    width: 100%;
    height: 44px;
    gap: 16px;
    /* border: 1px solid white; */
  }
  .footer-icon-flex {
    display: flex;
    justify-content: space-between;
  }
  .footer-icon-flex img {
    width: 34px;
    height: 34px;
  }

  .part-2-width {
    width: 100%;
    max-width: 175.88px;
    min-width: 124px;
    height: 297px;
    /* border: 1px solid white; */
    text-align: left;
  }
  .quick-link-heading {
    font-family: Roboto-Bold;
    font-weight: 700;
    font-size: 16px;
    line-height: 35px;
    color: white;
  }
  .quick-padding {
    padding: 15px 0 17px 0;
  }
  .links-policy {
    /* padding-top: 30px; */
  }
  .links-policy p {
    font-family: Roboto-Medium;
    font-weight: 500;
    font-size: 13px;
    line-height: 30px;
    color: white;
  }

  .part-3-width {
    width: 100%;
    max-width: 175.88px;
    min-width: 124px;
    height: 307px;
    /* border: 1px solid white; */
    text-align: left;
  }
  .our-product {
    /* padding-top: 30px; */
  }
  .our-product p {
    font-family: Roboto-Medium;
    font-weight: 500;
    font-size: 13px;
    line-height: 30px;
    color: white;
  }
  .our-product p a {
    font-family: Roboto-Medium;
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    color: white;
    text-decoration: none;
  }
  .part-4-width {
    width: 100%;
    max-width: 400.71px;
    min-width: 181px;
    height: 307px;
    /* border: 1px solid white; */
    text-align: left;
  }
  .copyright {
    background: black;
    color: white;
    padding: 15px 0;
  }
  .copyright .copy {
    font-family: Roboto-Regular;
    font-weight: 400;
    font-size: 13px;
    line-height: 35px;
  }
  .copyright .devlopBy {
    font-family: Roboto-Regular;
    font-weight: 400;
    font-size: 11px;
    line-height: 35px;
  }
}
@media screen and (max-width: 767px) {
  .footer-space {
    padding: 50px 15px;
  }
  .d-flex-footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 25px;
    padding: unset;
  }
  .part-1-width {
    width: 100%;
    max-width: 750px;
    min-width: 181px;
    height: unset;
    /* border: 1px solid white; */
    text-align: left;
  }

  .flonix-white-logo img {
    width: 180px;
    height: 62px;
  }
  .footer-pera {
    margin: 15px 0 36px 0;
  }
  .footer-pera p {
    font-family: Roboto-Medium;
    font-weight: 500;
    font-size: 13px;
    line-height: 30px;
    text-align: justify;
    color: #ffffff;
  }
  .footer-icon-width {
    max-width: 260px;
    min-width: 173px;
    width: 100%;
    height: unset;
    gap: 16px;
    /* border: 1px solid white; */
  }
  .footer-icon-flex {
    display: flex;
    justify-content: space-between;
  }
  .footer-icon-flex img {
    width: 34px;
    height: 34px;
  }

  .part-2-width {
    width: 100%;
    max-width: 750px;
    min-width: 124px;
    height: unset;
    /* border: 1px solid white; */
    text-align: left;
  }

  .quick-link-heading {
    font-family: Roboto-Bold;
    font-weight: 700;
    font-size: 16px;
    line-height: 35px;
    color: white;
  }
  .quick-padding {
    padding: 15px 0 17px 0;
  }
  .links-policy {
    /* padding-top: 30px; */
  }
  .links-policy p {
    font-family: Roboto-Medium;
    font-weight: 500;
    font-size: 13px;
    line-height: 30px;
    color: white;
  }

  .part-3-width {
    width: 100%;
    max-width: 750px;
    min-width: 124px;
    height: unset;
    /* border: 1px solid white; */
    text-align: left;
  }
  .our-product {
    /* padding-top: 30px; */
  }
  .our-product p {
    font-family: Roboto-Medium;
    font-weight: 500;
    font-size: 13px;
    line-height: 30px;
    color: white;
  }
  .our-product p {
    font-family: Roboto-Medium;
    font-weight: 500;
    font-size: 13px;
    line-height: 30px;
    color: white;
  }
  .our-product p a {
    font-family: Roboto-Medium;
    font-weight: 500;
    font-size: 13px;
    line-height: 30px;
    color: white;
    text-decoration: none;
  }
  .part-4-width {
    width: 100%;
    max-width: 750px;
    min-width: 181px;
    height: unset;
    /* border: 1px solid white; */
    text-align: left;
  }
  .copyright {
    background: black;
    color: white;
    padding: 15px 0;
  }
  .copyright .copy {
    font-family: Roboto-Regular;
    font-weight: 400;
    font-size: 13px;
    line-height: 35px;
  }
  .copyright .devlopBy {
    font-family: Roboto-Regular;
    font-weight: 400;
    font-size: 11px;
    line-height: 35px;
  }
}
