.d-grid-contact {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-items: flex-start;
  align-items: center;
  gap: 50px 10px;
}
.d-grid-contact > div:nth-child(3) {
  grid-column: 2 / span 2; /* Start from the first column and span two columns */
  align-self: flex-start;
}
.contact-page-padding {
  padding: 0;
}
.d-grid-contact-oue-network {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
  gap: 50px 10px;
}

.contact-form-box {
  max-width: 703px;
  min-width: 279px;
  width: 100%;
  height: 564px;
  /* border: 1px  solid black; */
  opacity: 0px;
}
.contact-form-heading {
  font-family: PlayfairDisplay-Bold;
  font-weight: 700;
  font-size: 24px;
  line-height: 35px;
  text-align: left;
  padding-bottom: 41px;
}

.contact-flex {
  display: flex;
  justify-content: space-between;
  text-align: left;
  gap: 33px;
}

.contact-form-inputs {
  width: 334px;
  padding: 20px;
  margin: 5px 0px;
  border-radius: 15px;
  border: 1px solid black;
}
.contact-flex-up-down-margin {
  display: flex;
  justify-content: space-between;
  text-align: left;
  gap: 33px;
  margin: 18.5px 0;
}

.contact-form-label-textarea .contact-form-label {
  font-family: Roboto-Regular;
  font-weight: 400;
  font-size: 18px;
  line-height: 40px;
}
.contact-form-label-textarea .contact-form-textarea {
  max-width: 701px;
  min-width: 279px;
  width: 100%;
  padding: 40px 20px;
  /* margin: 5px 0; */
  border-radius: 15px;
  border: 1px solid black;
}

.contact-form-button {
  margin: 26px 0 0 0;
  text-align: left;
}

.contact-form-button button {
  background: black;
  color: white;
  padding: 15px 25px;
  border-radius: 365px;
  font-family: Roboto-Bold;
  font-weight: 700;
  font-size: 18px;
  cursor: pointer;
  border: none;
}

.contact-map {
  max-width: 1320px;
  min-width: 250px;
  height: 564px;
  width: 100%;
}
.contact-map iframe {
  max-width: 1320px;
  min-width: 250px;
  height: 564px;
  width: 100%;
  border-radius: 15px;
  border: none;
}
.contact-location-box {
  max-width: 364px;
  min-width: 279px;
  width: 100%;
  height: 287px;
  border-radius: 10px;
  box-shadow: rgba(39, 170, 243, 0.25) 0px 2px 8px 0px;
}
.contact-location-box-padding {
  padding: 28px 35px;
  /* border: 1px solid black; */
}

.contact-location-box-padding .contact-main-icon img {
  width: 72px;
  height: 72px;
}
.contact-location-box-padding .location-icon-heading p {
  font-family: Roboto-Medium;
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  padding: 20px 0;
  text-align: center;
}
.contact-location-box-padding .location-icon-pera p {
  text-align: justify;
  font-family: Roboto-Regular;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  text-align: center;
}

.contact-our-network {
  margin: 0 auto 50px auto;
  max-width: 1039px;
  min-width: 250px;
  width: 100%;
}
.contact-our-network .conatact-our-network-heading {
  font-family: Roboto-Regular;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  text-align: center;
}

.contact-our-network-box {
  max-width: 409.31px;
  min-width: 250px;
  width: 100%;
  /* height: 200px; */
  border-radius: 10px;
  box-shadow: rgba(39, 170, 243, 0.25) 0px 2px 8px 0px;
}

.contact-why-box {
  padding: 17px 45px 34px 15px;
  /* border: 1px solid black; */
}
.why-flonix-filteration-heading {
  font-family: Roboto-Medium;
  font-size: 18px;
  font-weight: 500;
  line-height: 35px;
  text-align: left;
}
.contact-location-and-mobile-icon-text {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  text-align: justify;
  gap: 0 10px;
}

.create-padding {
  padding-top: 16px;
}

.contact-form-icon-addres-location {
  width: 30px;
  height: 30px;
}

.contact-form-icon-addres-location img {
  width: 30px;
  height: 30px;
}
.contact-addres-location-pera p a {
  font-family: Roboto-Regular;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  /* padding: 0 0 0 10px; */
  color: black;
  text-decoration: none;
}

/* contact */
.inquiry-icon-width {
  max-width: 502px;
  min-width: 250px;
  width: 100%;
  /* border: 1px solid black; */
}
.inquiry-icon-flex {
  display: flex;
  text-align: left;
  align-items: center;
  gap: 10px;
}
.form-address .form-heading {
  font-family: Roboto-Medium;
  font-weight: 500;
  font-size: 24px;
  line-height: 28.13px;
}
.form-address .form-pera a {
  font-family: Roboto-Regular;
  font-weight: 400;
  font-size: 18px;
  line-height: 21.09px;
  text-decoration: none;
  color: black;
}

.inquiry-icon-flex-up-down-margin {
  display: flex;
  text-align: left;
  align-items: center;
  gap: 10px;
  margin: 90px 0;
}

.form-width {
  max-width: 764px;
  min-width: 250px;
  width: 100%;
  /* border: 1px solid black; */
}

.form-flex {
  display: flex;
  justify-content: space-between;
  text-align: left;
  gap: 59px;
  max-width: 964px;
  min-width: 250px;
  width: 100%;
}
.form-flex-up-down-margin {
  display: flex;
  justify-content: space-between;
  text-align: left;
  gap: 59px;
  margin: 41.5px 0;
  max-width: 964px;
  min-width: 250px;
  width: 100%;
}
.form-label-input .form-label {
  font-family: Roboto-Regular;
  font-weight: 400;
  font-size: 18px;
  line-height: 40px;
}
/* .form-label-input{

        } */
.form-label-input {
  width: 100%;
  max-width: 370px;
  min-width: 100px;
  /* margin: 5px 0; */
  border-radius: 15px;
  /* padding: 20px; */
  /* border: 1px solid black; */
}
.form-inputs {
  width: 100%;
  max-width: 370px;
  min-width: 100px;
  border-radius: 15px;
  padding: 20px;
  font-size: 18px;
  font-family: Roboto-Regular;
  font-weight: 400;
}
.form-label-textarea {
  max-width: 964px;
  min-width: 250px;
  width: 100%;
}

.form-label-textarea .form-label {
  font-family: Roboto-Regular;
  font-weight: 400;
  font-size: 18px;
  line-height: 40px;
}

.form-textarea {
  max-width: 964px;
  min-width: 250px;
  width: 100%;
  padding: 10px 20px;
  /* margin: 5px 0; */
  border-radius: 15px;
  border: 2px solid black;
  font-size: 18px;
  font-family: Roboto-Regular;
  font-weight: 400;
  height: 110px;
}
/* .form-button{
          margin: 50px 0 0 0;
          text-align: left;
        } */

.form-button button {
  background: black;
  color: white;
  /* padding: 15px 25px; */
  border-radius: 365px;
  font-family: Roboto-Bold;
  font-weight: 700;
  font-size: 18px;
  cursor: pointer;
  border: none;
}

@media screen and (max-width: 1024px) {
  .d-grid-contact {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    justify-items: normal;
    gap: 50px 10px;
  }
  .contact-page-padding {
    padding: 0 30px;
  }
  .form-width {
    max-width: 964px;
    min-width: 250px;
    width: 100%;
    /* border: 1px solid black; */
  }
  .d-grid-contact-oue-network {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
    gap: 50px 10px;
  }
  .d-grid-contact > div:nth-child(3) {
    grid-column: unset;
    align-self: flex-start;
  }
  .form-address .form-heading {
    font-family: Roboto-Medium;
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
  }
  .form-address .form-pera a {
    font-family: Roboto-Regular;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
  }
  .form-label-input .form-label {
    font-family: Roboto-Regular;
    font-weight: 400;
    font-size: 16px;
    line-height: 40px;
  }
  .form-button button {
    background: black;
    color: white;
    padding: 15px 25px;
    border-radius: 365px;
    font-family: Roboto-Bold;
    font-weight: 700;
    font-size: 16px;
    cursor: pointer;
    border: none;
    text-align: center;
  }
  .contact-our-network .conatact-our-network-heading {
    font-family: Roboto-Regular;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    text-align: center;
  }
  .why-flonix-filteration-heading {
    font-family: Roboto-Medium;
    font-size: 20px;
    font-weight: 500;
    line-height: 27px;
    text-align: left;
  }
  .contact-addres-location-pera p a {
    font-family: Roboto-Regular;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    /* padding: 0 0 0 10px; */
    color: black;
    text-decoration: none;
  }
}
@media screen and (max-width: 767px) {
  .d-grid-contact {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    justify-items: normal;
    gap: 50px 10px;
  }
  .d-grid-contact > div:nth-child(3) {
    grid-column: unset;
    align-self: flex-start;
  }
  .contact-page-padding {
    padding: 0 15px;
  }
  .d-grid-contact-oue-network {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    justify-items: center;
    gap: 50px 10px;
  }
  .form-flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: unset;
    text-align: left;
    gap: 59px;
    max-width: 800px;
    min-width: 250px;
    width: 100%;
  }
  .form-flex-up-down-margin {
    display: flex;
    flex-wrap: wrap;
    justify-content: unset;
    text-align: left;
    gap: 59px;
    margin: 41.5px 0;
    max-width: 764px;
    min-width: 250px;
    width: 100%;
  }
  .form-label-input {
    width: 100%;
    max-width: 764px;
    min-width: 100px;
    /* margin: 5px 0; */
    border-radius: 15px;
    /* padding: 20px; */
    /* border: 1px solid black; */
  }
  .form-inputs {
    width: 100%;
    max-width: 764px;
    min-width: 100px;
    border-radius: 15px;
    padding: 20px;
  }
  .form-address .form-heading {
    font-family: Roboto-Medium;
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
  }
  .form-address .form-pera a {
    font-family: Roboto-Regular;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
  }
  .form-label-input .form-label {
    font-family: Roboto-Regular;
    font-weight: 400;
    font-size: 14px;
    line-height: 40px;
  }
  .form-button button {
    background: black;
    color: white;
    padding: 15px 25px;
    border-radius: 365px;
    font-family: Roboto-Bold;
    font-weight: 700;
    font-size: 16px;
    cursor: pointer;
    border: none;
  }
  .contact-our-network .conatact-our-network-heading {
    font-family: Roboto-Regular;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
  }
  .why-flonix-filteration-heading {
    font-family: Roboto-Medium;
    font-size: 16px;
    font-weight: 500;
    line-height: 27px;
    text-align: left;
  }
  .contact-addres-location-pera p a {
    font-family: Roboto-Regular;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    /* padding: 0 0 0 10px; */
    color: black;
    text-decoration: none;
  }
}
